import React, {useState,useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { CardContent} from '@material-ui/core';
import { Chip } from '@material-ui/core';
import {Container} from '@material-ui/core';
import { Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { IconButton } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { LocalOfferOutlined } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import {Modal} from '@material-ui/core';
import { Paper } from '@material-ui/core';
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography';
import { TableContainer } from '@material-ui/core';
import { TableHead } from '@material-ui/core';
import { Table } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { TableBody } from '@material-ui/core';

//component
import Comment from '../components/Comment';
import ImageGallaryComponent from '../components/ImageGallaryComponent';
import Loader from '../components/Loader'
import Message from '../components/Message'
import SizeNotation from '../components/SizeNotation';
import RelatedProductComponent from '../components/RelatedProductComponent';

//action
import { createBasicCartItem } from '../actions/cartAction';
import { createPremiumCartItem } from '../actions/cartAction';
import { favorite_create, favorite_delete} from '../actions/favoriteAction';
import { getVariation, get_related_product } from '../actions/productActions';
import { imageGallaryAction } from '../actions/imageGallaryAction';
import { get_product_detail } from '../actions/productActions';
import { reviewList } from '../actions/productActions';
import { reservation_already_rental } from '../actions/reservationAction';

//function
import { pageView } from '../function/gaTraking';
import { headRewrite } from '../function/headRewrite';

import { Helmet } from 'react-helmet-async';

import axios from 'axios';

const useStyles = makeStyles((theme) => ({
    button:{
        marginTop: theme.spacing(1),
        textAlign:'center',
        width:'100%',
        backgroundColor: 'white',
        color:'black',
        "&:disabled": {
            color: 'black',
            backgroundColor: theme.palette.primary['400']
        },
        [theme.breakpoints.down('sm')]:{
            display:'none'
        },
    },
    button_top:{
        marginBottom: theme.spacing(1),
        textAlign:'center',
        width:'100%',
        backgroundColor: 'white',
        color:'black',
        "&:disabled": {
            color: 'black',
            backgroundColor: theme.palette.primary['400']
        },
        [theme.breakpoints.up('md')]:{
            display:'none'
        }
    },
    chip:{
        color:'black',
        backgroundColor: 'white',
        '&:hover':{
            backgroundColor: theme.palette.primary['200']
        },
        marginRight:theme.spacing(1),
        marginTop: theme.spacing(1)
    },
    comment:{
        height:'100%',
        overflow:'hidden'
    },
    error_container:{
        height:'100vh'
    },
    error_title:{
        margin: theme.spacing(3)
    },
    error_message:{
        marginTop: theme.spacing(2)
    },
    detail:{
        width:'100%',
        overflow:'hidden'
    },
    favorite: {
        [theme.breakpoints.up('xs')]:{
            width:'30px',
            height:'30px'
        },
        [theme.breakpoints.up('sm')]:{
            width:'40px',
            height:'40px'
        },
        [theme.breakpoints.up('md')]:{
            width:'45px',
            height:'45px'
        },
        [theme.breakpoints.up('lg')]:{
            width:'50px',
            height: '50px',
        },
        color: "#b71c1c",  
    },
    favoriteBorder:{
        [theme.breakpoints.up('xs')]:{
            width:'30px',
            height:'30px'
        },
        [theme.breakpoints.up('sm')]:{
            width:'40px',
            height:'40px'
        },
        [theme.breakpoints.up('md')]:{
            width:'45px',
            height:'45px'
        },
        [theme.breakpoints.up('lg')]:{
            width:'50px',
            height: '50px',
        },
        color: theme.palette.primary["800"]
    },

    formControl:{
        width:"100%",
    },

    icon: {
        zIndex:1000,
        [theme.breakpoints.down('xs')]:{

        },
        [theme.breakpoints.up('xs')]:{
            top:'80%',
            left:'3%',
        },
        [theme.breakpoints.up('sm')]:{
            top:'80%',
            left:'3%',
        },
        [theme.breakpoints.up('md')]:{
            top:'80%',
            left:'3%',
        },
        [theme.breakpoints.up('lg')]:{
            top:'85%',
            left:'3%',
        },
        position:'absolute',
    },

    iconButton:{
        backgroundColor: theme['palette']['primary']['200'],
        '&:hover':{
            backgroundColor: theme['palette']['primary']['400']
        }
    },
    inner:{
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1)
    },
    fill_tag:{
        color: theme.palette.primary['400']
    },
    loader:{
        [theme.breakpoints.down('sm')]:{
            marginTop:'50%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('sm')]:{
            marginTop:'35%',
            textAlign:'center',
            minHeight:'100vh',
        },
        [theme.breakpoints.up('md')]:{
            marginTop:'30%',
            textAlign:'center',
            minHeight:'100vh',
        },
    },
    media: {
        width:"100%",
        height:'100%',
        position: 'relative'
    },
    modal_button:{
        marginTop: theme.spacing(3),
        color: 'black',
        backgroundColor: theme.palette.primary['200'],
        '&:hover':{
            backgroundColor: theme.palette.primary['300']
        }
    },
    modal_card: {
        color:'black',
        backgroundColor:'white',
        margin: theme.spacing(0,1,0,1)
    },
    modal_container:{
        height:'500px',
        position:'absolute',
        top:'50%',
        left:'50%',
        transform: 'translate(-50%, -50%)',
        padding: 'initial',
        index:1000
    },
    product_description:{
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    root:{
        [theme.breakpoints.up('xs')]:{
            margin: theme.spacing(3, 1, 15, 1)
        },
        [theme.breakpoints.up('sm')]:{
            margin: theme.spacing(5, 2, 15, 2)
        },
        [theme.breakpoints.up('md')]:{
            margin:theme.spacing(7, 3, 15 ,3),
        }
    },
    select_root:{
        borderBottom: '1px solid black'
    },
    select_icon:{
        fill:'black'
    },
    size:{
        marginTop:theme.spacing(2),
        marginBottom:theme.spacing(2)
    },
    tags:{
        marginTop: theme.spacing(1)
    },
    tags_outer:{
        marginTop: theme.spacing(1)
    },
    title:{
        marginTop: theme.spacing(3)
    },
    typography:{
        marginBottom: theme.spacing(1),
    }
}))

const ProductScreen = () => {

    const classes = useStyles()
    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()
    const {id} = useParams()

    //配達日
    const delivary_first_day = new Date()
    const delivary_last_day = new Date()
    delivary_last_day.setDate(delivary_last_day.getDate() + 3);
    const return_date = new Date()
    return_date.setDate(return_date.getDate() + 14);

    //state
    const [favoriteData, setFavoriteData] = useState(null)
    const [favoriteState, setFavoriteState] = useState(false)
    const [quantity, setQuantity] = useState(1);
    const [open, setOpen] = useState(false)
    
    //useSelector
    const {accountInfo} = useSelector(state => state.account)
    const {cart} = useSelector(state => state.cart)
    const {favorite, favorite_error} = useSelector(state => state.favorite)
    const { favorites } = useSelector(state => state.favoriteList)
    const {variations, variation_error} = useSelector(state => state.variation)
    
    //productの詳細を取得する
    const {loading, product, error} = useSelector(state => state.product)
    const {review_loading, reviews, review_error} = useSelector(state => state.reviews)
    const {subscriptionInfo} = useSelector(state => state.subscriptionInfo)

    //head data
    const title = product ? product.product_name + '｜ファッションレンタル' : '';
    const description = product ? product.product_name + 'をファッションレンタルサービスEnclopedia（アンクロペディア）で借りよう' : ''
    const keyword = "ファッションレンタルサービス、サブスクリプション、アンクロペディア、enclopedia"

    headRewrite(title, description, keyword)

    //お気に入りを取得
    const get_favorite = async() => {
        if(accountInfo){
            const config = {'headers':{'Content-Type': 'application/json', 'Authorization': `Bearer ${accountInfo.access}`}}
            await axios.get(process.env.REACT_APP_URL + `/api/history/favorite/?product=${id}`,config).then(
                (response) => {
                    setFavoriteState(response['data'] ? true : false)
                    setFavoriteData(response['data'])
                }
            )
        }
    }
    

    const handleLogin = () => {
        history.push('/login')
    }

    const favoriteHandler = () => {
        try{
            if(favoriteState === false){
                if(favorites.length >= 30 ){
                //お気に入りを30以上追加できないようにする
                    setOpen(true)
                }else{
                    dispatch(favorite_create(product.id))
                    setFavoriteState(true)
                }
            }else{
                if(favoriteData){
                    dispatch(favorite_delete(favoriteData.id))
                    setFavoriteData(null)
                }else{
                    dispatch(favorite_delete(favorite['id']))
                }
                setFavoriteState(false)
            }
        }catch(error){
            console.log(error)
        }
        
    }

    //prodcut idをurlに使用するよりもproduct/nameを使用した方がいい
    const addToCarHandler = async() => {
        try{
            if(accountInfo){
                if(subscriptionInfo.plan === 'basic'){
                    await dispatch(createBasicCartItem(cart['id'], id, quantity, variations, product['stock']))
                }else if(subscriptionInfo.plan === 'premium'){
                    await dispatch(createPremiumCartItem(cart['id'], id, quantity, variations, product['stock']))
                }else{
                    await(dispatch(createBasicCartItem(cart['id'], id, quantity, variations, product['stock'])))
                }

                window.gtag('event', 'add_to_cart', {
                    items:[{
                        "item_id": product.id,
                        "item_name": product.product_name,
                        "item_brand": product.brand.brand_name,

                        quantity:1,
                    }]
                });

                history.push(`/`)
                //dispatch(createBasicCartItem(accountInfo.access, cart['id'], id, quantity, variations, product[(stock)]))
            }
            
        }catch{
            //ここでaccountInfoが有効でないとcart_idが有効でなくなる。
            history.push(`/`)
        }
    }

    const handleChange = (event) => {setQuantity(event.target.value)}
    
    //関連タグ商品一覧ページに飛ぶようにする
    const handleTag = (id) => {
        history.push(`/tag/${id}/`)
    }

    //modalを閉じる処理
    const handleClose = () => {
        setOpen(false)
    }

    //component内でuseeffectを使用すると2回画面のレンダリングあるため2回queryを送信してしまう
    //premiumプラン加入者は全て表示
    useEffect(() => {
        dispatch(get_product_detail(id))
        pageView(`product:${id}`, location.pathname);
        dispatch(imageGallaryAction(id))
        dispatch(reviewList(id))
        dispatch(getVariation(id))
        dispatch(get_related_product(id))
        if(accountInfo){
            dispatch(reservation_already_rental(id))
            get_favorite()
        }
    },[])

    //subscriptionInfoがretalかbasic premiumかで分ける必要がある。
    return (
        <>
        {
        loading ?
        <div className={classes.loader}>
            <Loader/> 
        </div>
        :
        error ?
        <div className={classes.error_container}>
            <Typography variant="subtitle1" component="div" className={classes.error_title}>商品の詳細を取得できませんでした</Typography>
        </div>
        :
        <>
        <Helmet>
            {
            product ?
            <>
            <meta name="thumbnail" content={product.img}/>
            <script type="application/ld+json">
                {
                    JSON.stringify({
                        "@context": "https://schema.org/",
                        "@type": "Product",
                        "name": product.product_name,
                        "image": [product.img],
                        "description": product.description,
                        "brand":{
                            "@type": "Brand",
                            "name": product.brand.brand_name
                        },
                        "offers":{
                            "@type": "Offer",
                            "url": `https://www.enclopedia-official.com/product/${product.id}`,
                            "priceCurrency": "JPY",
                            "price": product.price.price,
                            "itemCondition": "https://schema.org/UsedCondition",
                            "availability": product.stock > 0 ? "https://schema.org/InStock" : " https://schema.org/OutOfStock",
                        }
                    })
                }
            </script>
            </>
            :
            ''
            }
        </Helmet>
        <div className={classes.root}>
            <Typography variant='h1'component='h1' className={classes.title} style={{marginBottom:10, fontWeight:'bold'}}>
                    {product ? product.product_name:''}
            </Typography>
            <Grid key={1} container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} className={classes.media}>
                    <div className={classes.media}>
                        <ImageGallaryComponent/>
                        {
                            accountInfo ?
                            favorite_error ?
                            <Message>{favorite_error.message}</Message> :
                            <div className={classes.icon}>
                                <IconButton className={classes.iconButton} onClick={favoriteHandler}>
                                    {favoriteState?<FavoriteIcon className={classes.favorite} />: <FavoriteBorderIcon className={classes.favoriteBorder} />}
                                </IconButton>
                            </div>
                            :
                            ''
                        }
                    </div>
                 </Grid>
                
                <Grid key={2} item xs={12} sm={12} md={6} lg={6}  className={classes.detail}>
                    {
                        product ?
                        product.stock === 0 ?
                        <Button
                        onClick = {addToCarHandler} 
                        variant="contained" 
                        disabled={true}
                        className={classes.button_top}>
                            在庫がありません
                        </Button>
                        :
                        subscriptionInfo.plan === 'premium' ?
                        <Button
                        onClick = {addToCarHandler} 
                        variant="contained" 
                        className={classes.button_top}>
                            予約リストに加える
                        </Button>
                        :
                        (subscriptionInfo.plan === 'basic') || ( subscriptionInfo.plan === 'rental') ?
                        product.is_subscription === 'premium' ?
                        <Button
                        variant="contained" 
                        disabled={true} 
                        className={classes.button_top}>
                            プレミアムプランのみレンタル可能です
                        </Button>
                        :
                        <Button
                        onClick = {addToCarHandler} 
                        variant="contained" 
                        className={classes.button_top}>
                            予約リストに加える
                        </Button>
                        :
                        <Button
                        variant="contained" 
                        onClick={handleLogin}
                        className={classes.button_top}>
                            ログインしてください
                        </Button>
                        :
                        ''
                    }
                    {
                        product ?
                        subscriptionInfo.plan === 'rental' | subscriptionInfo.plan === '' ?
                        <Card style={{color:'black', backgroundColor:'white'}}>
                            <CardContent className={classes.inner}>
                                <Typography variant='subtitle1' component='p'>
                                    対象プラン:  {<strong>{product ? product.is_subscription === 'basic'? 'レンタルプラン、ベーシックプラン': product.is_subscription === 'premium' ? 'プレミアムプラン' : 'レンタルプラン、ベーシックプラン' : 'アカウントを作成して下さい'}</strong>}
                                </Typography>
                                <Typography variant='subtitle2' component='p' style={{marginBottom:10}}>
                                    ※上記に該当するサブスクリプションプラン加入者のみ予約することが可能です
                                </Typography>
                                
                                <Typography variant="subtitle1" component="p" style={{marginBottom:10}}>レンタル料: <strong>{product ? product.price.price:''}</strong> 円</Typography>
                    
                                <Typography variant="subtitle1" component="p" style={{marginBottom:10}}>返却手数料: <strong>{product ? product.price.shipping.shipping_price : ''}</strong> 円</Typography>  
                                <Typography variant='subtitle2' component="p" dispaly='block' style={{ color: '#ff6f00', fontWeight: 'bold', fontSize: '12px'}}>お届け日：{(delivary_last_day.getMonth() + 1) + '月' + delivary_last_day.getDate() + '日にお届け'}</Typography>
                                <Typography variant="subtitle2" component="p" display='block' style={{color: '#ff6f00', fontWeight: 'bold', fontSize: '12px'}}>返却日：{(return_date.getMonth()+1) + '月' + return_date.getDate() + '日'}までに返却を行なって下さい</Typography>
                                <Typography variant='subtitle2' component="p" dispaly='block' style={{color: '#ff6f00', fontWeight: 'bold', fontSize: '12px'}}>配送： {product ? product.price.shipping.shipping_company:''}</Typography>
                                <Typography variant="subtitle1" component='p' dipaly='block' style={{marginBottom:10}}>※返却手数料はまとめてレンタルすることでお得になります</Typography>
                
                                <Typography variant="subtitle1" component='p'>{(product? product['stock'] : 0)> 0 ? `在庫数：${product ?product['stock']:''} 個` : '在庫なし'}</Typography>
                
                            <Divider />
                            {(product ? product['stock'] : 0) > 0 && (
                                    <FormControl className={classes.formControl} style={{color:'black'}}>
                                        <InputLabel id="product_quantity" style={{color:'black'}}>レンタル数</InputLabel>
                                        <Select
                                        style={{color:'black'}}
                                        inputProps={{
                                            classes:{
                                                root: classes.select_root,
                                                icon: classes.select_icon,
                                            }
                                        }}
                                        labelId="product_quantity"
                                        id="product_quantity_select"
                                        value={quantity}
                                        onChange={handleChange}
                                        >
                                            {
                                                [...Array(product ? product['stock']: '').keys()].map((stock) => (
                                                    <MenuItem key={stock+1} value={stock+1}>{stock+1}</MenuItem>
                                                ))
                                            }
                                                
                                        </Select>
                                    </FormControl>
                            )}
                                <Typography component="p">合計金額:  <strong>{product ? product.price.price + product.price.shipping.shipping_price : ''}</strong> 円</Typography>
                            </CardContent>
                        </Card>
                        :
                        <Card style={{color:'black', backgroundColor:'white'}}>
                            <CardContent className={classes.inner}>
                                <Typography variant='subtitle1' component='p'>
                                    対象プラン:  {<strong>{product ? product.is_subscription === 'basic'? 'レンタルプラン、ベーシックプラン': product.is_subscription === 'premium' ? 'プレミアムプラン' : 'レンタルプラン、ベーシックプラン' : 'アカウントを作成して下さい'}</strong>}
                                </Typography>
                                <Typography variant='subtitle2' component='p' style={{marginBottom:10}}>
                                    ※プレミアムプランのお客様はベーシックプランの商品もレンタルが可能です。
                                </Typography>
                                
                                <Typography variant='subtitle2' component='p' dispaly='block' style={{ color: '#ff6f00'}}>お届け日：{(delivary_first_day.getMonth() + 1) + '月' + delivary_first_day.getDate() + '日' + '〜' + (delivary_last_day.getMonth() + 1) + '月' + delivary_last_day.getDate() + '日までにお届け'}</Typography>
                                <Typography variant="subtitle2" component='p' display='block' style={{color: '#ff6f00'}}>返却日：{(return_date.getMonth()+1) + '月' + return_date.getDate() + '日'}までに返却を行なって下さい。</Typography>
                                <Typography variant="subtitle1" component='p' dipaly='block' style={{marginBottom:10}}>※サブスクリプションプランに加入している方は返却手数料はかかりません。</Typography>
                
                                <Typography>{(product? product['stock'] : 0)> 0 ? `在庫数：${product ?product['stock']:''} 個` : '在庫なし'}</Typography>
                
                            <Divider />
                            {(product ? product['stock'] : 0) > 0 && (
                                    <FormControl className={classes.formControl} style={{color:'black'}}>
                                        <InputLabel id="product_quantity" style={{color:'black'}}>レンタル数</InputLabel>
                                        <Select
                                        style={{color:'black'}}
                                        inputProps={{
                                            classes:{
                                                root: classes.select_root,
                                                icon: classes.select_icon,
                                            }
                                        }}
                                        labelId="product_quantity"
                                        id="product_quantity_select"
                                        value={quantity}
                                        onChange={handleChange}
                                        >
                                            {
                                                [...Array(product ? product['stock']: '').keys()].map((stock) => (
                                                    <MenuItem key={stock+1} value={stock+1}>{stock+1}</MenuItem>
                                                ))
                                            }
                                                
                                        </Select>
                                    </FormControl>
                            )}
                            </CardContent>
                        </Card>
                        :
                        ''
                    }
                    {
                        product ?
                        product.stock === 0 ?
                        <Button
                        onClick = {addToCarHandler} 
                        variant="contained" 
                        disabled={true}
                        className={classes.button}>
                            在庫がありません
                        </Button>
                        :
                        subscriptionInfo.plan === 'premium' ?
                        <Button
                        key="premium_button"
                        onClick = {addToCarHandler} 
                        variant="contained" 
                        className={classes.button}>
                            予約リストに加える
                        </Button>
                        :
                        (subscriptionInfo.plan === 'basic') || ( subscriptionInfo.plan === 'rental') ?
                        product.is_subscription === 'premium' ?
                        <Button
                        key="not_premium_button"
                        variant="contained" 
                        disabled={true} 
                        className={classes.button}>
                            プレミアムプランのみレンタル可能です
                        </Button>
                        :
                        <Button
                        key="list_button"
                        onClick = {addToCarHandler} 
                        variant="contained" 
                        className={classes.button}>
                            予約リストに加える
                        </Button>
                        :
                        <Button
                        key="login_button"
                        variant="contained"
                        onClick={handleLogin}
                        className={classes.button}>
                            ログインしてください
                        </Button>
                        :
                        ''
                    }
                    <div className={classes.product_description}>
                        <Typography variant="subtitle1" component="p" className={classes.typography} >
                            商品名:
                        </Typography>
                        <Typography variant="subtitle1" component="h2" className={classes.typography} >
                            {product ? product['product_name']:''}
                        </Typography>
                        <Divider />
                        <Typography variant="subtitle1" component="p" style={{marginTop:10}} className={classes.typography} >
                            ブランド:
                        </Typography>
                        <Typography variant="subtitle1" component="h2" className={classes.typography} >
                            {product ? product.brand.brand_name:''}
                        </Typography>
                        <Divider />
                        <Typography variant="subtitle1" style={{marginTop:10}} component="p" className={classes.typography} > 
                            商品紹介:
                        </Typography>
                        <Typography variant="subtitle1" style={{marginBottom:10, whiteSpace: 'pre-line'}} component="p">
                            {product ? product.description:''}
                        </Typography>
                        <Divider />
                        <Typography variant="subtitle1" style={{marginTop:10}} component="p" className={classes.typography} > 
                            購入価格:
                        </Typography>
                        <Typography variant="subtitle1" style={{marginBottom:5, whiteSpace: 'pre-line'}} component="p">
                            {product ? product.buying_price + ' 円':''}
                        </Typography>
                        <Typography variant="subtitle1" style={{marginBottom:10, whiteSpace: 'pre-line'}} component="p">
                            ※レンタル中のみ購入が可能です
                        </Typography>
                        <Divider />
                        <Typography variant="subtitle1" style={{marginTop:10}} component="p" className={classes.typography}>
                            性別:
                        </Typography>
                        <Typography variant="subtitle1" style={{marginBottom:10}} component="p">
                            {product ? product.gender: ''}
                        </Typography>
                        <Divider/>
                        {
                        variation_error ?
                        ''
                        :
                        variations.map((variation) => (
                            <>
                            <Typography style={{marginTop:10}} variant="subtitle1" component="p" className={classes.typography}>
                                {variation.variation_choices}:
                            </Typography>
                            <Typography variant="subtitle1" component="p" className={classes.typography}>
                                {variation.variation_value}
                            </Typography>
                            <Divider/>
                            </>
                        ))
                        
                        }
                        <Typography variant="subtitle1" component="p" className={classes.tags_outer}>
                            関連タグ
                        </Typography>

                        <div className={classes.tags}>
                            {(product ? product.tag : []).map((t, index)=>(
                                <Chip icon={<LocalOfferOutlined className={classes.fill_tag}/>} key={index} label={product ? t.tag_name : ''} className={classes.chip} onClick={() => {handleTag(t.id)}}/>
                            ))}
                        </div> 
                    </div>
                    <Divider />
                    <Paper sx={{ width:'100%', overflow:'hidden', backgroundColor:'white'}} className={classes.size}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                    {
                        <Table sx={{minWidth:'500px', backgroundColor:'white', overflow:'hidden',}} aria-label="size table">
                            <TableHead>
                                <TableRow>
                                    <TableCell key={1} component="p" style={{minWidth:'170px'}}>サイズ表</TableCell>
                                    <TableCell key={2} component="p" style={{minWidth:'100px'}} align="right">着丈</TableCell>
                                    <TableCell key={3} component="p" style={{minWidth:'100px'}}  align="right">肩幅</TableCell>
                                    <TableCell key={4} component="p" style={{minWidth:'100px'}}  align="right">身幅</TableCell>
                                    <TableCell key={5} component="p" style={{minWidth:'100px'}}  align="right">袖丈</TableCell>
                                    <TableCell key={6} component="p" style={{minWidth:'100px'}}  align="right">ウエスト</TableCell>
                                    <TableCell key={7} component="p" style={{minWidth:'100px'}}  align="right">ワタリ</TableCell>
                                    <TableCell key={8} component="p" style={{minWidth:'100px'}} align="right">股上</TableCell>
                                    <TableCell key={9} component="p" style={{minWidth:'100px'}} align="right">股下</TableCell>
                                    <TableCell key={10} component="p" style={{minWidth:'100px'}} align="right">裾幅</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                product ?
                                product.size.map(s => (
                                    <SizeNotation product_size={s} key={s['id']}/>
                                ))
                                :
                                ''
                                }
                            </TableBody>
                        </Table>
                    }
                    </TableContainer>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} className={classes.related}>
                    <RelatedProductComponent product_id={id}/>
                </Grid>
                <Grid item xs={12} sm={12} className={classes.comment}>
                    {review_loading ?
                        <Loader/>
                        :
                        review_error ?
                        <Typography variant="subtitle2" component="p">口コミはありません</Typography>
                        :
                        <Comment comments={reviews} product={product ? product : []}/>
                    }
                </Grid>
            </Grid>
            <Modal
             open={open}
             onClose={handleClose}
             aria-labelledby="modal-modal-title"
             >
                <Container maxWidth={"sm"} className={classes.modal_container}>
                    <Card className={classes.modal_card}>
                        <CardContent>
                            <Typography variant="subtitle1" component="p" align="center" style={{fontWeight: 'bold'}}>
                                エラー
                            </Typography>
                            <Typography variant="subtitle2" component="p" className={classes.error_message}>
                                {"お気に入りの登録数が制限を超えたため、これ以上登録できません。\
                                \n登録済みの不要なお気に入りリストから削除してください。"}
                            </Typography>
                            <Button
                            onClick={handleClose}
                            className={classes.modal_button}
                            fullWidth>
                                閉じる
                            </Button>
                        </CardContent>
                    </Card> 
                </Container>
             </Modal>
        </div>
        </>
        }
    </>
    )
}
export default ProductScreen
